// extracted by mini-css-extract-plugin
export var copy = "footer-module--copy--96a57";
export var copyWrap = "footer-module--copyWrap--f7fe4";
export var footer = "footer-module--footer--bb1f4";
export var footerOuter = "footer-module--footerOuter--67dcb";
export var icon = "footer-module--icon--4747e";
export var iconHover = "footer-module--iconHover--29097";
export var iconWrap = "footer-module--iconWrap--6dc71";
export var info = "footer-module--info--7a914";
export var infoItem = "footer-module--infoItem--c0635";
export var infos = "footer-module--infos--540d3";
export var link = "footer-module--link--e9b94";
export var linksWrap = "footer-module--linksWrap--2e329";
export var logoSocialLine = "footer-module--logoSocialLine--de7da";
export var menu = "footer-module--menu--b9dbb";
export var menuItem = "footer-module--menuItem--354e7";
export var nip = "footer-module--nip--5a7dd";
export var socialButton = "footer-module--socialButton--1fc82";
export var socials = "footer-module--socials--db85c";
export var socialsMobile = "footer-module--socialsMobile--2ec8b";
export var yellow = "footer-module--yellow--2a4ae";