import React from 'react';
import * as styles from './logo.module.scss';
import classNames from 'classnames';
import {Link} from 'gatsby';
import logo from '../../../images/logo.svg';
import logoWhite from '../../../images/logo-white.svg';
import logoSmall from '../../../images/logo-small.svg';

const Logo = ({className, alwaysBig = false, yellow = false}) => {
    return (
        <>
            {yellow && <>
                <Link to="/" className={classNames(styles.logoWrap, className, {[styles.alwaysBig]: alwaysBig})}>
                    <img className={styles.logo} src={logoWhite} alt="Wojtko"/>
                </Link>
                <Link to="/" className={classNames(styles.logoWrapMobile, className, {[styles.alwaysBig]: alwaysBig})}>
                    <img className={styles.logoMobile} src={logoSmall} alt="Wojtko"/>
                </Link>
            </>
            }
            {!yellow && <>
                <Link to="/" className={classNames(styles.logoWrap, className, {[styles.alwaysBig]: alwaysBig})}>
                    <img className={styles.logo} src={logo} alt="Wojtko"/>
                </Link>
                <Link to="/" className={classNames(styles.logoWrapMobile, className, {[styles.alwaysBig]: alwaysBig})}>
                    <img className={styles.logoMobile} src={logoSmall} alt="Wojtko"/>
                </Link>
            </>
            }
        </>
    );
};

export default Logo;
