import React from 'react';
import * as styles from './footer.module.scss';
import Logo from '../logo/logo';
import classNames from 'classnames';
import {Link} from 'gatsby';
import instagram from '../../../images/socials/instagram.svg';
import instagramWhite from '../../../images/socials/instagram-white.svg';
import facebook from '../../../images/socials/facebook.svg';
import facebookWhite from '../../../images/socials/facebook-white.svg';
import phoneIcon from '../../../images/icons/phone.svg';
import phoneIconYellow from '../../../images/icons/phone-yellow.svg';
import mailIcon from '../../../images/icons/mail.svg';
import mailIconYellow from '../../../images/icons/mail-yellow.svg';
import localeIcon from '../../../images/icons/locale.png';
import localeIconYellow from '../../../images/icons/locale-yellow.png';

const Footer = () => (
    <footer id='footer' className={styles.footerOuter}>
        <div className={styles.footer}>
            <div className={styles.logoSocialLine}>
                <Logo alwaysBig/>
                <div className={styles.socials}>
                    <a href='https://www.facebook.com/profile.php?id=100063545834517'
                       className={classNames(styles.socialButton)}
                       target='_blank' rel='noreferrer'>
                        <img src={facebook} className={styles.icon} alt='facebook'/>
                        <img src={facebookWhite} className={classNames(styles.icon, styles.iconHover)} alt='facebook'/>
                    </a>
                    <a href='https://www.instagram.com/tapicerwojtko/' className={classNames(styles.socialButton)}
                       target='_blank' rel='noreferrer'>
                        <img src={instagram} className={styles.icon} alt='instagram'/>
                        <img src={instagramWhite} className={classNames(styles.icon, styles.iconHover)}
                             alt='instagram'/>
                    </a>
                </div>
            </div>
            <div className={styles.linksWrap}>
                <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                        <Link className={classNames('link', styles.link)} to='/'>Strona główna</Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link className={classNames('link', styles.link)} to='/#uslugi'>Usługi</Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link className={classNames('link', styles.link)} to='/realizacje'>Realizacje</Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link className={classNames('link', styles.link)} to='/o-nas'>O nas</Link>
                    </li>
                </ul>
                <ul className={styles.infos}>
                    <li className={styles.infoItem}>
                        <a href='tel: +48 667 867 338' className={classNames('display link museo', styles.info)}>
                            <span className={styles.iconWrap}>
                                <img className={styles.icon} src={phoneIcon} alt='Telefon'/>
                                <img className={classNames(styles.icon, styles.yellow)} src={phoneIconYellow} alt='Telefon'/>
                            </span>
                            +48 667 867 338
                        </a>
                    </li>
                    <li className={styles.infoItem}>
                        <a href='mailto: wojtko4@wp.pl' className={classNames('display link museo', styles.info)}>
                            <span className={styles.iconWrap}>
                                <img className={styles.icon} src={mailIcon} alt='Mail'/>
                                <img className={classNames(styles.icon, styles.yellow)} src={mailIconYellow} alt='Mail'/>
                            </span>
                            wojtko4@wp.pl
                        </a>
                    </li>
                    <li className={styles.infoItem}>
                        <a href='https://maps.app.goo.gl/UUP93GsfbCzRRyFQ9' target='_blank' className={classNames('display museo', styles.info)}>
                            <span className={styles.iconWrap}>
                                <img className={styles.icon} src={localeIcon} alt='Lokalizacja'/>
                                <img className={classNames(styles.icon, styles.yellow)} src={localeIconYellow} alt='Lokalizacja'/>
                            </span>
                            Łużycka 70, 61-614, Poznań
                        </a>
                    </li>
                    <li className={styles.infoItem}>
                        <p className={classNames('display museo', styles.info, styles.nip)}>NIP: 9720225803</p>
                    </li>
                </ul>
            </div>
            <div className={styles.socialsMobile}>
                <a href='https://www.facebook.com/profile.php?id=100063545834517'
                   className={classNames(styles.socialButton)}
                   target='_blank' rel='noreferrer'>
                    <img src={facebook} className={styles.icon} alt='facebook'/>
                </a>
                <a href='https://www.instagram.com/tapicerwojtko/' className={classNames(styles.socialButton)}
                   target='_blank' rel='noreferrer'>
                    <img src={instagram} className={styles.icon} alt='instagram'/>
                </a>
            </div>
            <div className={styles.copyWrap}>
                <p className={classNames(styles.copy, styles.link)}>Polityka prywatności</p>
                <p className={styles.copy}>Wszystkie prawa są zastrzeżone Wojtko © 2023</p>
            </div>
        </div>
    </footer>
);

export default Footer;
