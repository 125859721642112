/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import React from 'react';
import './src/styles/main.scss';
import Navbar from './src/components/shared/navbar/navbar';
import Footer from './src/components/shared/footer/footer';
import {Helmet} from 'react-helmet';

export function shouldUpdateScroll({routerProps: {location}, getSavedScrollPosition}) {
    const {hash} = location;

    if (hash) {
        setTimeout(() => {
            const yOffset = window.innerWidth <= 767 ? -80 : -60;
            const element = document.getElementById(hash.substring(1));
            if (element) {
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        }, 0);
        return false;
    }

    setTimeout(() => window.scrollTo(0, 0), 10);
    return false;
}

export const wrapRootElement = ({element}) => (
    <>
        <Helmet>
            <meta charSet="utf-8"/>
            <meta
                name="description"
                content="Naprawa i renowacja tapicerki meblowej w skórze oraz materiale, panele tapicerowane na zamówienie. Przywracamy waszym meblom młodość."
            />
        </Helmet>
        {element}
        <Footer/>
    </>
);
