import React, {useEffect, useRef, useState} from 'react';
import * as styles from './navbar.module.scss';
import Logo from '../logo/logo';
import {Link} from 'gatsby';
import classNames from 'classnames';


const Navbar = ({yellow = false}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const burgerRef = useRef(null);
    const menuRef = useRef(null);

    const handleBurgerClick = () => {
        setMenuOpen(!menuOpen);
    };

    const closeBurger = () => {
        setMenuOpen(false);
    };

    return (
        <nav className={classNames(styles.navbar, {[styles.yellowBackground]: yellow})}>
            <div className={styles.navbarWrap}>
                <Logo yellow={yellow}/>
                <div
                    ref={menuRef}
                    className={classNames(styles.menuWrap, {[styles.active]: menuOpen})}
                >
                    <ul className={styles.menu}>
                        <li className={styles.menuItem}>
                            <Link to="/#uslugi" className={classNames('link', styles.menuLink)} onClick={closeBurger}>
                                Usługi
                            </Link>
                        </li>
                        <li className={styles.menuItem}>
                            <Link to="/realizacje" className={classNames('link', styles.menuLink)} onClick={closeBurger}>
                                Realizacje
                            </Link>
                        </li>
                        <li className={styles.menuItem}>
                            <Link to="/o-nas" className={classNames('link', styles.menuLink)} onClick={closeBurger}>
                                O nas
                            </Link>
                        </li>
                        <li className={classNames(styles.menuItem, styles.mobile)}>
                            <Link to="#footer" className={classNames('link', styles.menuLink)} onClick={closeBurger}>
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </div>
                <Link to='#footer' className={classNames(styles.cta, 'ctaButton')}>
                    Skontaktuj się
                </Link>
                <Link to='#footer' className={classNames(styles.ctaMobile)} onClick={closeBurger}>
                    Skontaktuj się
                </Link>
                <Link to='#footer' className={classNames(styles.ctaMobile, styles.yellow)} onClick={closeBurger}>
                    Skontaktuj się
                </Link>
                <button ref={burgerRef} className={classNames(styles.burger, {[styles.active]: menuOpen})} onClick={handleBurgerClick}>
                    menu
                </button>
            </div>
        </nav>
    );
};
export default Navbar;
